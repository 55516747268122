<script setup>

  import Fx from '@/lib/functions.js';

  import { ref } from 'vue';

  import TicketFormView from '@/views/TicketFormView';
  // import TicketsView from '@/views/TicketsView';

  import ReloadPWA from "@/components/ReloadPWA.vue";
  

  const ticketsTableKey = ref( false );

  window.setTimeout( function(){
    ticketsTableKey.value = Fx.getTime();
  }, 500 );



</script>


<template>

  <div class="home">
    <!-- <h1 class="sectionTitle">
      <i class="bi bi-house"></i> 
      {{ $t('app.home') }} 

      <i 
        class="bi bi-arrow-clockwise float-end refresh"
        @click="ticketsTableKey = Fx.getTime()"
      ></i>

    </h1>

    <p class="text-start mb-0"> {{ $t('home.lastTickets') }} </p> -->
    

    <div class="mb-4">
      <TicketFormView />
    </div>

<!--     <hr clas="mt-4 mb-2">
    <div class="">
      <TicketsView
      />
    </div> -->

    <ReloadPWA :modal="true" />

  </div>
</template>
