<script setup>

  import Settings from '@/lib/settings.js';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n({ legacy: false }); 

  const loginUrl = encodeURI( Settings.SAML_URL + 'login/index.php' );

</script>

<template>
  <div style="height: 100vh">
    <div class=" d-flex align-items-center  h-screen justify-center" style="height: 100%;">
      <div class="notLoggedIn m-auto p-4">
        <p class="mb-0 p-0">
          <i class="bi bi-lock" style="font-size: 72px;"></i>
        </p>
        <h1 class="m-0">{{ $t('notLoggedIn.notLoggedIn')}}</h1>
        <p>{{ $t('notLoggedIn.notLoggedInText')}}</p>
        
        <a class="btn btn-info text-white"
          :href="loginUrl" >
          <i class="bi bi-key"></i> 
          {{ t('notLoggedIn.login') }}
        </a>
      </div>
    </div>
  </div>
</template>
