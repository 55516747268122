export default {

	send: "Send",
	file: "File",
	actions: "Actions",
	subject: "Subject",

	status: {
		new: "New",
		open: "Open",
		pending: "Pending",
		solved: "Solved",
		closed: "Closed",
		hold: "On hold"
	},

	app: {
		home: "Home",
		ticket: "Ticket",
		tickets: "Tickets",
		createTicket: "Create ticket",
		createNewTicket: "Crete new ticket",
		config: "Settings",
		logout: "Exit",
		logoutQuestion: "Are you sure that you want to log out?",
		yesLogout: "Yes, logout",
		noLogout: "No, cancel",
		sessionClosed: "Your session has been closed"
	},

	pwa: {
		addToHomePrompt: "Do you want to install this app?",
		addButton: "Yes, install",
		cancelButton: "No, cancel",
		appIsInstalled: "Install is not possible. If the app is not already install, refresh your browser and try again.",
		iOSAlert: "You can install this app in your phone by clicking on the Share icon in Safari, and then Add to Home Screen",
		dontAskAgain: "Do not ask again"
	},

	etools: {
		etools: "E-tools",
		travelReservationTravelpark: "Travel reservation - Travelpark",
		travelReservationAmex: "Travel reservation - Amex GTB",
		IESECardRecharge: "IESE Card balance query or recharge",
		visitManagement: "Visit Management",
		ocupationQuery: "Ocupation spaces query",
		catalog: "Catalog",
		stationery: "Papelería con logo"
	},

	tickets: {
		ticketList: "Tickets",
		id: "Id.",
		status: "Status",
		date: "Date",
		subject: "Subject",
		seeTicket: "See ticket",
		noTickets: "No tickets where found",
		loading: "Loading...",
		saving: "Saving...",
		saveSuccess: "Your request was saved successfully. It can take a few seconds until the new ticket is visible, please be patient",
		errorSaving: "There was an error",
		statusState: {
			new: "New",
			open: "Open",
			pending: "Pending",
			solved: "Solved",
			closed: "Closed",
			hold: "On hold"
		}
	},

	ticketForm: {
		create: "Create ticket",
		title: "Ticket type",
		incident: "Incident / Request",
		classReservation: "Classrooms / Workrooms Reservation",
		lunchReservation: "Lunch table reservation",
		parking: "FreePass Parking",
		other: "Other",
		suggestion: "Suggestions",
		selectTicketType: "Select a ticket type",
		subject: "Subject",
		lists: {
			limpieza: "Cleaning",
			temperatura: "Temperature",
			averia: "Breakdown",
			peticion: "Suggestion",
			otros: "Other",
			classroom: "Classroom",
			workspace: "Workspace",
			other: "Other",
			norte: "North",
			sur: "South",
			este: "East"
		},
		incidentForm: {
			// onBehalf: "In behalf of",
			campus: "Campus",
			space: "Space",
			description: "Description",
			files: "Photos or files"
		},
		classReservationForm: {
			// onBehalf: "In behalf of",
			campus: "Campus",
			bcnCampusZone: "Campus Zone (BCN)",
			madCampusZone: "Campus Zone (MAD)",
			spaceType: "Space Type",
			reservationDate: "Reservation Date",
			reservationTimeStart: "Reservation Time Start",
			reservationTimeEnd: "Reservation Time End",
			description: "Description",
			alert: "Remember that you can make your reservation by Outlook, 24×7. Here you can find more details about the auto reservation option:"
		},
		lunchReservationForm: {
			// onBehalf: "In behalf of",
			campus: "Campus",
			madComedor: "Dining room (MAD)",
			reservationDate: "Reservation Date",
			reservationTimeStart: "Reservation Time Start",
			quantity: "Number of diners",
			costCentre: "Cost centre",
			description: "Description"
		},
		parkingForm: {
			// onBehalf: "In behalf of",
			campus: "Campus",
			parkingDate: "Parking Date",
			costCentre: "Cost centre (e.g. A456 or \"Particular\")",
			description: "Description",
			alert: "Information about the parking"
		},
		otherForm: {
			// onBehalf: "In behalf of",
			campus: "Campus",
			description: "Description",
			files: "Photos or files"
		},
		suggestionForm: {
			// onBehalf: "In behalf of",
			campus: "Campus",
			description: "Description",
			files: "Photos or files"
		}

	},

	ticketDetail: {
		ticketNumber: "Ticket #",
		createdAt: "Created:",
		updatedAt: "Last modif.:",
		description: "Description",
		comments: "Comments",
		responseText: "Response",
		files: "Files",
		ticketType: "Request type"
	},

	config: {
		config: "Settings",
		language: "Language",
		en: "English",
		es: "Español"
	},

	notFound: {
		notFound: "Page Not Found",
		notFoundText: "The page you where trying to get does not exist.",
		goHome: "Go to Home Page"
	},

	noConnection: {
		noConnection: "No connection",
		noConnectionText: "You have no connection. Please connect to the Internet."
	},

	notLoggedIn: {
		notLoggedIn: "Not logged in",
		notLoggedInText: "You are not logged in. Please click below to log in.",
		login: "Log in"
	},

	selector: {
		placeholder: "Select option",
		selectLabel: "Press enter to select"
	},

	errors: {
		errorUploadingFile: "Error uploading the file",
		datesNotValid: "The dates are not valid"
	},

	ticketForms: {
		incident: "Incident",
		classReservation: "Class / Workroom reservation",
		lunchReservation: "Lunch table reservation",
		parking: "FreePass Parking",
		other: "Other",
		suggestion: "Suggestion"
	},

	ticketFormFields: {
		campus: "Campus",
		bcnCampusZone: "Campus Zone (BCN)",
		madCampusZone: "Campus Zone (MAD)",
		madComedor: "Dining room (MAD)",
		space: "Space",
		spaceType: "Space time",
		reservationDate: "Reservation date",
		reservationTimeStart: "Reservation start time",
		reservationTimeEnd: "Reservation time end",
		quantity: "Quantity",
		costCentre: "Cost Centre"
	},

	dataTables: {
		"translations":{}
	}

};