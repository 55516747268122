export default{

	SAML_URL: process.env.VUE_APP_SAML_URL,
	BASE_API: process.env.VUE_APP_API_URL,
	BASE_URL: process.env.VUE_APP_URL,
	TEST_EMAIL: process.env.VUE_APP_TEST_EMAIL,
	JSON_TICKET_FIELDS: JSON.parse( process.env.VUE_APP_JSON_TICKET_FIELDS ),
	JSON_TICKET_FORMS: JSON.parse( process.env.VUE_APP_JSON_TICKET_FORMS ),

	DEFAULT_LANG: "es",

	DATE_FORMAT: {
		es: "DD/MM/YYYY HH:mm:ss",
		en: "MM/DD/YYYY HH:mm:ss"
	},

	select2Settings:{
		minimumResultsForSearch: 5
	},

	campusOptions:[
		{text:'---', id:'0'},
		{text:'Barcelona', id:'campus_bcn'},
		{text:'Madrid', id:'campus_mad'}
	],

	bcnCampusZonesOptions:[
		{text:'---', id:'0'},
		{text:'ticketForm.lists.norte', id:'campus_bcn_norte'},
		{text:'ticketForm.lists.sur', id:'campus_bcn_sur'},
		{text:'ticketForm.lists.este', id:'campus_bcn_este'}
	],

	madCampusZonesOptions:[
		{text:'---', id:'0'},
		{text:'Master', id:'campus_mad_master'},
		{text:'Executive', id:'campus_mad_executive'}
	],

	madComedorOptions:[
		{text:'---', id:'0'},
		{text:'Master', id:'comedor_master'},
		{text:'Executive', id:'comedor_executive'}
	],

	spaceTypeOptions:[
		{text: '---', id:'0'},
		{text: 'ticketForm.lists.classroom', id:'reserva_aula'},
		{text: 'ticketForm.lists.workspace', id:'reserva_sala_de_trabajo'},
		{text: 'ticketForm.lists.other', id:'reserva_otros_espacios'}
	],

	validationPatterns: {
		costCentrePattern: /(^[a-zA-Z][0-9][0-9][0-9]|Particular)$/
	},

	reservationHoursLimit: 2
};