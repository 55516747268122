export default {

	send: "Enviar",
	file: "Archivo",
	actions: "Acciones",
	subject: "Asunto",

	status: {
		new: "Nuevo",
		open: "Abierto",
		pending: "Pendiente",
		solved: "Resuelto",
		closed: "Cerrado",
		hold: "En espera"
	},

	app:{
		home:"Inicio",
		ticket:"Ticket",
		tickets:"Tickets",
		createTicket: "Crear ticket",
		createNewTicket: "Crear nuevo ticket",
		config: "Config.",
		logout: "Salir",
		logoutQuestion: "¿Seguro que quieres cerrar tu sesión?",
		yesLogout: "Sí, cerrar",
		noLogout: "No, cancelar",
		sessionClosed: "Su sesión ha sido cerrada"
	},

	pwa: {
		addToHomePrompt: "¿Quieres instalar esta aplicación?",
		addButton: "Sí, instalar",
		cancelButton: "No, cancelar",
		appIsInstalled: "No es posible instalar. Si la aplicación no está instalada, refresque su navegador e inténtelo de nuevo.",
		iOSAlert: "Puedes instalar esta aplicación en tu móvil haciendo click en el icono de Compartir en Safari, y luego Añadir a página de inicio",
		dontAskAgain: "No volver a preguntar"
	},

	etools: {
		etools: "E-tools",
		travelReservationTravelpark: "Reserva viajes - Travelperk",
		travelReservationAmex: "Reserva viajes - Amex GTB",
		IESECardRecharge: "Recarga o consulta saldo de tu IESE Card",
		visitManagement: "Gestión acceso visitas al campus",
		ocupationQuery: "Consulta las reservas de aulas",
		catalog: "Catálogo",
		stationery: "Papelería con logo"
	},

	tickets:{
		ticketList: "Tickets",
		id:"Id.",
		status:"Estatus",
		date:"Fecha",
		subject:"Asunto",
		seeTicket: "Ver ticket",
		noTickets: "No se encontraron tickets",
		loading: "Cargando tickets...",
		saving: "Guardando...",
		saveSuccess: "Tu petición ha sido guardada correctamente. Ten en cuenta que los cambios pueden tardar unos segundos en reflejarse",
		errorSaving: "Error al guardar",
		statusState: {
			new: "Nuevo",
			open: "Abierto",
			pending: "Pendiente",
			solved: "Resuelto",
			closed: "Cerrado",
			hold: "En espera"
		}
	},

	ticketForm: {
		create: "Crear ticket",
		title: "Tipo de ticket",
		incident: "Incidencia / Petición",
		classReservation: "Reserva Aulas / Salas Trabajo",
		lunchReservation: "Reserva mesa comedor",
		parking: "FreePass Parking",
		other: "Otros",
		suggestion: "Sugerencias",
		selectTicketType: "Seleccione un tipo de solicitud para crear un ticket",
		subject: "Asunto",
		lists: {
			limpieza: "Limpieza",
			temperatura: "Temperatura",
			averia: "Avería",
			peticion: "Petición",
			otros: "Otros",
			classroom: "Aula",
			workspace: "Espacio de trabajo",
			other: "Otros",
			norte: "Norte",
			sur: "Sur",
			este: "Este"
		},
		incidentForm: {
			// onBehalf: "En nombre de",
			campus: "Campus",
			space: "Lugar (opcional)",
			description: "Descripción",
			files: "Fotos o archivos"
		},
		classReservationForm: {
			// onBehalf: "En nombre de",
			campus: "Campus",
			bcnCampusZone: "Zona del campus (BCN)",
			madCampusZone: "Zona del campus (MAD)",
			spaceType: "Tipo de espacio",
			reservationDate: "Fecha de reserva",
			reservationTimeStart: "Hora de inicio de reserva",
			reservationTimeEnd: "Hora de fin de reserva",
			description: "Descripción",
			alert: "Recuerda que disponemos de la reserva a través de Outlook, sistema inmediato 24×7. Aquí encontraréis más detalles para aprender de manera rápida el uso de esta opción de auto reserva"
		},
		lunchReservationForm: {
			// onBehalf: "En nombre de",
			campus: "Campus",
			madComedor: "Comedor (MAD)",
			reservationDate: "Fecha de reserva",
			reservationTimeStart: "Hora de reserva",
			quantity: "Número de comensales",
			costCentre: "Centro de coste",
			description: "Descripción"
		},
		parkingForm: {
			// onBehalf: "En nombre de",
			campus: "Campus",
			parkingDate: "Fecha de aparcamiento",
			costCentre: "Centro de coste (ej. A456 or \"Particular\")",
			description: "Descripción",
			alert: "Información sobre el parking"
		},
		otherForm: {
			// onBehalf: "En nombre de",
			campus: "Campus",
			description: "Descripción",
			files: "Fotos o archivos"
		},
		suggestionForm: {
			// onBehalf: "En nombre de",
			campus: "Campus",
			description: "Descripción",
			files: "Fotos o archivos"
		}

	},

	ticketDetail: {
		ticketNumber: "Núm. Ticket",
		createdAt: "Creado:",
		updatedAt: "Última modif.:",
		description: "Descripción",
		comments: "Comentarios",
		responseText: "Respuesta",
		files: "Archivos",
		ticketType: "Tipo de petición"
	},

	config:{
		config:"Configuración",
		language:"Idioma",
		en:"English",
		es:"Español"
	},

	notFound: {
		notFound: "Página No Encontrada",
		notFoundText: "La página que intenta visitar no existe.",
		goHome: "Ir a inicio"
	},

	noConnection: {
		noConnection: "No hay conexión",
		noConnectionText: "No tienes conexión. Por favor, conéctate a una red."
	},
	
	notLoggedIn: {
		notLoggedIn: "No estás logueado",
		notLoggedInText: "No estás logueado. Por favor, haz click para acceder.",
		login: "Acceder"
	},

	selector: {
		placeholder: "Seleccione opción",
		selectLabel: "Presione Enter para seleccionar"
	},

	errors: {
		errorUploadingFile: "Error al subir el archivo",
		datesNotValid: "Las fechas no son válidas"
	},

	ticketForms: {
		incident: "Incidente",
		classReservation: "Reserva de Aula / Sala de trabajo",
		lunchReservation: "Reserva de mesa de comedor",
		parking: "FreePass Aparcamiento",
		other: "Otros",
		suggestion: "Sugerencia"
	},

	ticketFormFields: {
		campus: "Campus",
		bcnCampusZone: "Zona del campus (BCN)",
		madCampusZone: "Zona del campus (MAD)",
		madComedor: "Comedor (MAD)",
		space: "Espacio",
		spaceType: "Tipo de espacio",
		reservationDate: "Fecha de reserva",
		reservationTimeStart: "Hora de inicio de reserva",
		reservationTimeEnd: "Hora de fin de reserva",
		quantity: "Cantidad",
		costCentre: "Centro de coste"	
	},

	dataTables:{
		"translations":{
			"sProcessing":"Procesando...",
			"sLengthMenu":"Mostrar_MENU_registros",
			"sZeroRecords":"Noseencontraronresultados",
			"sEmptyTable":"Ningúndatodisponibleenestatabla",
			"sInfo":"Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
			"sInfoEmpty":"Mostrando registros del 0 al 0 de un total de 0 registros",
			"sInfoFiltered":"(filtrado de un total de _MAX_ registros)",
			"sInfoPostFix":"",
			"sSearch":"Buscar:",
			"sUrl":"",
			"sInfoThousands":",",
			"sLoadingRecords":"Cargando...",
			"oPaginate":{
				"sFirst":"Primero",
				"sLast":"Último",
				"sNext":"Siguiente",
				"sPrevious":"Anterior"
			}
		}
	}
};