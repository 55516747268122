<script setup>

  import Settings from '../lib/settings.js';
  import Fx from '@/lib/functions.js';
  import { getUserEmail } from '@/lib/project.js';

  import Select2 from 'vue3-select2-component';
  import { useI18n } from 'vue-i18n';

  import { ref, reactive } from 'vue';
  // import { useRouter } from 'vue-router';

  import IncidentFormView from './forms/IncidentFormView.vue';
  import ClassReservationFormView from './forms/ClassReservationFormView.vue';
  import LunchReservationFormView from './forms/LunchReservationFormView.vue';
  import ParkingFormView from './forms/ParkingFormView.vue';
  // import OtherFormView from './forms/OtherFormView.vue';
  import SuggestionFormView from './forms/SuggestionFormView.vue';

  // const router = useRouter();

  // import TicketsView from '@/views/TicketsView';


  const { t } = useI18n({ legacy: false });

  const ieseData = process.env.NODE_ENV == 'production'
      ? getUserEmail()
      : Settings.TEST_EMAIL;

  const options = [
    { text: '---', id: '0' },
    { text: t('ticketForm.incident'), id: 'incident' },
    { text: t('ticketForm.classReservation'), id: 'classReservation' },
    { text: t('ticketForm.lunchReservation'), id: 'lunchReservation' },
    { text: t('ticketForm.parking'), id: 'parking' },
    // { text: t('ticketForm.other'), id: 'other' },
    { text: t('ticketForm.suggestion'), id: 'suggestion' }
  ];

  let ticketTypeValue = reactive({ text: '---', id: '0' }),
    selectedTicketType = ref('---'),
    saving = ref(false),
    saveSuccess = ref(false),
    savingError = ref(false);

  let defaultObj = {
    
    subject: '',
    requester: ieseData,
    ticketType: selectedTicketType.value,

    campus: '',
    bcnCampusZone: '',
    madCampusZone: '',
    madComedor: '',
    space: '',
    spaceType: '',
    reservationDate: '',
    reservationTimeStart: '',
    reservationTimeEnd: '',
    quantity: 0,
    costCentre: '',

    description: '',
    files: []

  };

  let model = reactive( defaultObj );

  const updateTicketType = (formValue) => {
    
    selectedTicketType.value = formValue.text;
    
    clearForm();

  }

  const clearForm = () => {
    
    model.subject = '';

    model.campus = '';
    model.bcnCampusZone = '';
    model.madCampusZone = '';
    model.madComedor = '';
    model.space = '';
    model.spaceType = '';
    model.reservationDate = '';
    model.reservationTimeStart = '';
    model.reservationTimeEnd = '';
    model.quantity = 0;
    model.costCentre = '';

    model.description = '';

    model.files = [];

  }

  const incidentFormUpdated = ( formValues ) => {

    clearForm();

    if( formValues?.campus ){

      model.subject    = formValues.subject;
      model.ticketType = 'incident';

      model.campus       = formValues.campus;
      model.space        = formValues.space;
      model.description  = formValues.description;

      model.files = formValues.files;

      createTicket();

    }

  }

  const classReservationFormUpdated = ( formValues ) => {

    clearForm();

    if( formValues?.campus ){

      model.subject    = formValues.subject;
      model.ticketType = 'classReservation';

      model.campus               = formValues.campus;
      model.bcnCampusZone        = formValues.bcnCampusZone;
      model.madCampusZone        = formValues.madCampusZone;
      model.spaceType            = formValues.spaceType;
      model.reservationDate      = formValues.reservationDate;
      model.reservationTimeStart = formValues.reservationTimeStart;
      model.reservationTimeEnd   = formValues.reservationTimeEnd;
      model.description          = formValues.description;

      model.files = formValues.files;

      createTicket();
    }

  }

  const lunchReservationFormUpdated = ( formValues ) => {

    clearForm();

    if( formValues?.campus ){

      model.subject    = formValues.subject;
      model.ticketType = 'lunchReservation';

      model.campus               = formValues.campus;
      model.madComedor           = formValues.madComedor;
      model.reservationDate      = formValues.reservationDate;
      model.reservationTimeStart = formValues.reservationTimeStart;
      model.quantity             = formValues.quantity;
      model.costCentre           = formValues.costCentre;
      model.description          = formValues.description;

      model.files = formValues.files;

      createTicket();
    }

  }

  const parkingFormUpdated = ( formValues ) => {

    clearForm();

    if( formValues?.campus ){

      model.subject    = formValues.subject;
      model.ticketType = 'parking';

      model.campus      = formValues.campus;
      model.parkingDate = formValues.parkingDate;
      model.costCentre  = formValues.costCentre;
      model.description = formValues.description;

      model.files = formValues.files;

      createTicket();

    }

  }

  // const otherFormUpdated = ( formValues ) => {

  //   clearForm();

  //   if( formValues?.campus ){
  //     
  //     model.subject    = formValues.subject;
  //     model.ticketType = 'other';

  //     model.campus      = formValues.campus;
  //     model.description = formValues.description;

  //     model.files = formValues.files;
    
  //     createTicket();
  //   }

  // }

  const suggestionFormUpdated = ( formValues ) => {

    clearForm();

    if( formValues?.description ){

      model.subject    = formValues.subject;
      model.ticketType = 'suggestion';

      model.description = formValues.description;

      model.files = formValues.files;
    
      createTicket();
    }

  }

  const resetForm = () => {
    
    model = defaultObj;

    saving.value = false;
    savingError.value = false;
    saveSuccess.value = false;
  
  }

  const createTicket = () => {
  
    saving.value = true;
    savingError.value = false;
    saveSuccess.value = false;

    let url = Settings.BASE_API + 'tickets/index.php',
      dataObj = {
        ...model,
        files: JSON.stringify( model.files ),
        ieseData: ieseData
      };

    return Fx.post(url, dataObj, 'POST', Fx.defaultAPICallback )
      .then(function( data ) {

          if( !data || data.status == 'ko' || data.status == 409 ){
            
            saving.value = false;
            savingError.value = true;
            return true;
          }
          
          saving.value = false;
          if( data.status == 'ok' && data.ticket?.id ){
            // go to ticket
            
            saveSuccess.value = true;

            // router.push({ path: '/tickets/' + data.ticket.id});
            return true;
          }
          
      })
      .catch(function(error) {

        savingError.value = true;
        console.log("[saveTicket] Problema en el fetch: \n", error);
      });

  }

</script>

<template>
  <div class="ticketForm text-start">

    <h1 class="sectionTitle">
      <i class="bi bi-ticket-perforated"></i> 
      {{ $t('ticketForm.create') }} 
    </h1>

    <div v-if="saving" class="alert alert-info d-flex align-items-center" role="alert">
        <p class="m-0 p-0">
          <i class="bi bi-sync"></i> &nbsp;
          {{ t('tickets.saving') }}. 
        </p>
    </div>

    <div v-if="savingError" class="alert alert-danger d-flex align-items-center" role="alert">
        <p class="m-0 p-0">
          <i class="bi bi-warning"></i> &nbsp;
          {{ t('tickets.errorSaving') }}. 
        </p>
    </div>

    <div v-if="saveSuccess" class="alert alert-success d-flex" role="alert">
      <p class="m-0 p-0">
        {{ t('tickets.saveSuccess') }}. 
      
        <br><br>
        <a 
          class="btn bg-iese float-start"
          @click="resetForm()" >
          
          <i class="bi bi-plus-circle"></i>
          {{ $t('app.createNewTicket') }}
        </a>
      </p>

  </div>

    <div id="formContent" v-if="!saving && !savingError && !saveSuccess">

      <!-- <p class="m-0">{{$t('ticketForm.title')}}</p> -->
    
      <form id="ticketSelector" class="text-start">
        <Select2 
          :placeholder="$t('selector.placeholder')"
          :selectLabel="$t('selector.selectLabel')"
          :options="options"
          :settings="Settings.select2Settings"
          v-model="ticketTypeValue"
          @select="updateTicketType"
        >
          
        </Select2>

      </form>

      <div class="selectedForm">
        <h2 class="d-none" >Seleccionado: {{ selectedTicketType }}</h2>
        
        <div class="innerForm" v-if="!ticketTypeValue.id && ticketTypeValue != '0'">
          <IncidentFormView @incidentFormUpdated="incidentFormUpdated" v-if="ticketTypeValue == 'incident'" />
          <ClassReservationFormView @classReservationFormUpdated="classReservationFormUpdated"  v-if="ticketTypeValue == 'classReservation'" />
          <LunchReservationFormView @lunchReservationFormUpdated="lunchReservationFormUpdated" v-if="ticketTypeValue == 'lunchReservation'" />
          <ParkingFormView @parkingFormUpdated="parkingFormUpdated" v-if="ticketTypeValue == 'parking'" />
          <!-- <OtherFormView @otherFormUpdated="otherFormUpdated" v-if="ticketTypeValue == 'other'" /> -->
          <SuggestionFormView @suggestionFormUpdated="suggestionFormUpdated" v-if="ticketTypeValue == 'suggestion'" />
          <!-- <IncidentFormView  /> -->
        </div>
        
      </div>

      <div class="mt-2" v-if="ticketTypeValue.id || ticketTypeValue == '0'">
          <div class="alert alert-info d-flex align-items-center" role="alert">
            <p class="m-0 p-0">
              <i class="bi bi-info-circle"></i> &nbsp;
              {{ t('ticketForm.selectTicketType') }}. 
            </p>
          </div>
        </div>
    </div>
    
  </div>

  <!-- <div class="row mt-4">
    <div class="col-12 mb-4 mt-4">
      <TicketsView />
    </div>
  </div> -->
</template>

<style>
  .select2-container--default .select2-results__option--highlighted[aria-selected]{
    background: red !important;
  }

  .innerForm{
    padding: 9px;
    border: 1px solid #eee;
    background: #fafafa;
  }

</style>