import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/home/:id',
    name: 'homeWithEmail',
    component: HomeView
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  {
    path: '/tickets',
    name: 'tickets',
    // route level code-splitting
    // this generates a separate chunk (tickets.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "tickets" */ '../views/TicketsView.vue')
  },
  // {
  //   path: '/tickets/create',
  //   name: 'ticketForm',
  //   // route level code-splitting
  //   // this generates a separate chunk (ticketForm.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "ticketForm" */ '../views/TicketFormView.vue')
  // },
  {
    path: '/tickets/:id',
    name: 'ticketDetail',
    // route level code-splitting
    // this generates a separate chunk (ticketForm.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ticketDetail" */ '../views/TicketDetailView.vue')
  },
  {
    path: '/config',
    name: 'config',
    // route level code-splitting
    // this generates a separate chunk (ticketForm.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "config" */ '../views/ConfigView.vue')
  },
  {
    path: '/etools',
    name: 'etools',
    // route level code-splitting
    // this generates a separate chunk (ticketForm.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "etools" */ '../views/EtoolsView.vue')
  },
  {
    path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
    name: "notFound",
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "notFound" */ '../views/NotFoundView.vue')
  }
]

const router = createRouter({
  history: createWebHistory( process.env.BASE_URL ),
  routes
})

export default router
