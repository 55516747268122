import Settings from './settings';
import Moment from "moment";

const Fx = {
  
  enc: ( str ) => {
    
    let at_replace_str = 'qra217der15341585a',
      dot_replace_str = '9088asdf4875232';

      return str.replace(/[a-zA-Z]/g, function(c){
          return String.fromCharCode((c <= "Z" ? 90 : 122) >= (c = c.charCodeAt(0) + 13) ? c : c - 26);
      })
      .replace('@', at_replace_str)
      .replace('.', dot_replace_str);

  },

  getKeyByValue: (obj, value) => {
    return Object.keys(obj).find(key =>
        obj[key] === value);
  },

  browserLocale() {
    var userLang = navigator.language || navigator.userLanguage;
    let locale = userLang.substr(0,2).toLowerCase();

    if( locale != 'es' ){ locale = 'en'; }

    return locale;
  
  },

  // DATE FUNCTIONS
  dateFormat(dateString, locale) {

    var format = Settings.DATE_FORMAT[locale];

    return Moment(dateString)
      .locale(locale)
      .format(format);

  },

  dateDiff( dateString1, dateString2, unit = 'seconds' ){
    
    var a = Moment( dateString1 );
    var b = Moment( dateString2 );

    return a.diff(b, unit);

  },

  nowDateTime(){
    return Moment().format( 'YYYY-MM-DD HH:mm:ss' );
  },

  getTime() {
    var d = new Date(),
      time = d.getTime();

    return time;
  },


  // FETCH FUNCTIONS
  get: function(url, params, callback) {
    console.log("[GET] URL: " + url);
    console.log(params, "[GET] params: ");

    const urlObj = new URL(url);

    if (params) {
      urlObj.search = new URLSearchParams(params).toString();
    }

    return fetch(urlObj).then(callback());
  },

  post(url, dataObj, method = "POST", callback) {
    var model = new FormData();

    console.log(url, "[Fx.post]: url");
    console.log(dataObj, "[Fx.post]: dataObj");
    console.log(method, "[Fx.post]: method");

    if (!Settings.USE_HTTP_VERBS && (method == "PUT" || method == "DELETE")) {
      dataObj.method = method;
      method = "POST";
    }

    if( dataObj.f_alta_autom ){
      delete dataObj.f_alta_autom;
    }

    if( dataObj.f_modificacion_autom ){
      delete dataObj.f_alta_autom;
    }

    if (method == "POST") {
      for (var key in dataObj) {
        if (typeof dataObj[key] == "object") {
          for (var key2 in dataObj[key]) {
            model.append(key + "[" + key2 + "]", dataObj[key][key2]);
          }
        } else {
          model.append(key, dataObj[key]);
        }
      }
    } else {
      model = JSON.stringify(dataObj);
    }

    return fetch(url, {
      method: method,
      // mode: 'cors',
      body: model
    }).then(callback());
  },

  put(url, dataObj, callback) {
    return Fx.post(url, dataObj, "PUT", callback);
  },

  delete(url, dataObj, callback) {
    return Fx.post(url, dataObj, "DELETE", callback);
  },

  postFile(url, dataObj, fileObj, callback) {
    var inputFileName = fileObj.inputFileName,
      fileName = fileObj.fileName,
      fileContent = fileObj.fileContent;

    var model = new FormData();

    model.append(inputFileName, fileContent, fileName);

    if (typeof dataObj == "object") {
      Object.keys(dataObj).forEach(key => {
        model.append(key, dataObj[key]);
      });
    }

    return fetch(url, {
      method: "post",
      body: model
    }).then(callback());
  },

  noop() {
    return null;
  },

  defaultAPICallback() {
    return function(res) {
      // if (User.userInvalid(res)) {
      //   User.goToLogin();
      // }

      if( !res ){
        return {
          status: 'ko',
          error: 'Unknown error'
        }
      }

      return res.json();
    };
  },

};

export default Fx;
