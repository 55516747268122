
import Settings from '@/lib/settings.js';
import Fx from '@/lib/functions.js';

// PWA
const checkAppInstalled = async () => {

  if( !navigator || !navigator.getInstalledRelatedApps ){
    return [];
  }

  const relatedApps = await navigator.getInstalledRelatedApps();

  // console.log(relatedApps, 'relatedApps');
  return relatedApps && relatedApps.length > 0;
}

const isIOs = () => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent);
}
 
const isInStandaloneMode = () => {
  return (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://');
}
      




// File uploads
const changeMedia = (mediaObj, model) => {

  if( mediaObj.status == 'ko' ){
    alert( 'Error uploading file' );
    return false;
  }

  let requestData = mediaObj?.data;
  if( requestData?.status == 'ok' && requestData?.url_archivo ){
    model.files.push( requestData?.archivo );
  }

  return true;
  
}

const handleUploadError = (error) => {
  
  if( error?.response?.data?.error ){
    alert( error.response.data.error );
  }else if( error?.request?.response == '' ){
    alert( error?.message || 'Server error');
  }

  return true;

}

const handleImageRemoved = ( requestObj, model ) => {
  
  let url_archivo = requestObj?.data?.data?.url_archivo;

  if( url_archivo && url_archivo != '' ){

    let url = Settings.BASE_API + 'files/index.php',
      dataObj = {
        url_archivo: url_archivo
      };

    Fx.delete(url, dataObj, Fx.defaultAPICallback )
      .then(function() {
        model.files = model.files.filter(e => e !== url_archivo);
      })
      .catch(function(error) {
        console.log("[handleImageRemoved] Problema en el fetch: \n", error);
      });

  }

}

// user email
function storeUserEmail(email){

  let ieseData = window.localStorage.getItem('ieseData');

  if( ieseData == null ){
    window.localStorage.setItem('ieseData', Fx.enc( email ) );  
  }
  
}

function logoutUser(){
  window.localStorage.removeItem('ieseData');
}

function getUserEmail(){
  
  let email = window.localStorage.getItem('ieseData');

  if( email != null ){
    return email;
  }

  email = window.location.href
    .split('=')
    .pop()
    .replace('%40', '@');

  if( email.indexOf('@') > -1 ){
    return email;
  }

  return '';
  
}


// other
const pillClass = ( status ) => {

  if( status == 'new' ){ return 'text-bg-info'; }
  if( status == 'open' ){ return 'text-bg-warning'; }
  if( status == 'pending' ){ return 'text-bg-primary'; }
  if( status == 'solved' ){ return 'text-bg-danger'; }
  if( status == 'closed' ){ return 'text-bg-secondary'; }

  return 'text-bg-primary';
  
}

const setSelect2Options = ( t ) => {
  return item => {
    return {
      ...item,
      text: t( item.text  )
    }
  }
}

const getSelect2OptionText = ( obj, key ) => {
  return obj.find( (item) => item.id == key )?.text || '---';
}

const reservationDateValid = (reservationDate, reservationTimeStart, reservationTimeEnd = '23:59:59' ) => {

//  console.log(reservationDate, 'reservationDate');

  if( reservationDate == '' || reservationTimeStart == '' ){
    console.log('Faltan datos');
    return false;
  }

    let reservationStartDateTime = reservationDate + ' ' + reservationTimeStart,
      reservationEndDateTime = reservationDate + ' ' + reservationTimeEnd,
      nowDateTime = Fx.nowDateTime();

    // console.log( nowDateTime, 'nowDateTime ');
    // console.log( reservationStartDateTime, 'reservationStartDateTime ');
    // console.log( reservationEndDateTime, 'reservationEndDateTime ');
    // console.log( Fx.dateDiff( reservationStartDateTime, nowDateTime, 'hours' ) );

    if( Fx.dateDiff( reservationStartDateTime, nowDateTime, 'hours' ) < Settings.reservationHoursLimit ){
      console.log('Fecha de inicio reserva incorrecta');
      return false;
    }

    if( Fx.dateDiff( reservationEndDateTime, reservationStartDateTime, 'hours' ) < 1  ){
      console.log('Fecha de fin de reserva incorrecta');
      return false;
    }

    return true;

}


export { 
  changeMedia, 
  handleUploadError, 
  handleImageRemoved,
  storeUserEmail,
  logoutUser,
  getUserEmail,
  pillClass,
  setSelect2Options,
  getSelect2OptionText,
  reservationDateValid,
  checkAppInstalled,
  isIOs,
  isInStandaloneMode
}