<script setup>
  
  import Settings from '../../lib/settings.js';
  // import Fx from '../../lib/functions.js';

  import { reactive, computed, defineEmits } from 'vue';
  import { useI18n } from 'vue-i18n';

  import Select2 from 'vue3-select2-component';

  import { reservationDateValid } from '@/lib/project.js';

  const emit = defineEmits(['lunchReservationFormUpdated']);

  const { t } = useI18n({ legacy: false });

  let model = reactive({ 
      subject: t('ticketForm.lunchReservation'),
      type: 'lunchReservation',
      // onBehalf: '',
      campus: '',
      madComedor: '',
      reservationDate: '',
      reservationTimeStart: '',
      quantity: 1,
      costCentre: '',
      description: ''
  });

  let madComedorOptions = Settings.madComedorOptions.map( item => item.text = t( item.text  ) );

  // Select2
  const updateSelected = (selectObj, value) => {
    model[ value ] = selectObj.id;
  };

  // form submit
  // form validation
  const dateValid = computed( () => {
    return reservationDateValid( model.reservationDate, model.reservationTimeStart );
  }); 
  
  const formValid = computed(() => {

    return model.subject.length > 3 && 
      model.campus.length > 1 &&
      model.reservationDate.length > 1 &&
      model.reservationTimeStart.length > 1 &&
      model.quantity > 0 && 
      model.costCentre.length > 0 && 
      Settings.validationPatterns.costCentrePattern.test( model.costCentre );

  });

</script>

<template>
  <div class="lunchReservation">
    <h6 class="mt-3 text-left form_title">
      <i class="bi bi-caret-right"></i>
      {{ $t('ticketForm.lunchReservation') }}
    </h6>

    <div class="form row">
      <form :action="Settings.BASE_API + 'tickets/index.php'" method="post">

        <div class="mb-3">
          <label for="subject" class="form-label mb-0">{{ $t('ticketForm.subject') }}:</label>
          <input type="text" v-model="model.subject" class="form-control" id="subject" placeholder="" />
        </div>

        <div class="mb-3">
          <label for="campus" class="form-label mb-0">{{ $t('ticketForm.lunchReservationForm.campus') }}</label>
          <Select2 
            :placeholder="$t('selector.placeholder')"
            :selectLabel="$t('selector.selectLabel')"
            :options="Settings.campusOptions"
            :settings="Settings.select2Settings"
            v-model="model.campus"
            @select="updateSelected($event, 'campus')"
          />
        </div>

        <div 
            class="mb-3"
            v-show="model.campus == 'campus_mad'">
            <label for="madComedor" class="form-label mb-0">{{ $t('ticketForm.lunchReservationForm.madComedor') }}</label>
            <Select2 
              :placeholder="$t('selector.placeholder')"
              :selectLabel="$t('selector.selectLabel')"
              :options="madComedorOptions"
              :settings="Settings.select2Settings"
              v-model="model.madComedor"
              @select="updateSelected($event, 'madComedor')"
            />
        </div>

        <div class="mb-3">
          <label for="reservationDate" class="form-label mb-0">{{ $t('ticketForm.lunchReservationForm.reservationDate') }}:</label>
          <input type="date" v-model="model.reservationDate" class="form-control" id="reservationDate" placeholder="" />
        </div>

        <div class="mb-3">
          <label for="reservationTimeStart" class="form-label mb-0">{{ $t('ticketForm.lunchReservationForm.reservationTimeStart') }}:</label>
          <input type="time" v-model="model.reservationTimeStart" class="form-control" id="reservationTimeStart" placeholder="" />
        </div>

        <div 
          v-if="!dateValid"
          class="mb-3 alert alert-warning">
          <i class="bi bi-info-circle"></i> &nbsp;
          {{ $t('errors.datesNotValid') }}
        </div>

        <div class="mb-3">
          <label for="quantity" class="form-label mb-0">{{ $t('ticketForm.lunchReservationForm.quantity') }}:</label>
          <input type="number" step="1" min="1" max="99" v-model="model.quantity" class="form-control" id="quantity" placeholder="" />
        </div>

        <div class="mb-3">
          <label for="costCentre" class="form-label mb-0">{{ $t('ticketForm.lunchReservationForm.costCentre') }}:</label>
          <input type="text" v-model="model.costCentre" class="form-control" id="costCentre" placeholder="" pattern="(^[a-zA-Z][0-9][0-9][0-9]|Particular)$" required />
        </div>

        <div class="mb-3">
          <label for="description" class="form-label mb-0">{{ $t('ticketForm.lunchReservationForm.description') }}:</label>
          <textarea v-model="model.description" class="form-control" id="description" placeholder="" required ></textarea>
        </div>

        <div class="col-auto mb-3">
          

          <button 
            @click.prevent="emit('lunchReservationFormUpdated', model);"
            :disabled="!formValid"
            type="submit" 
            class="btn btn-iese mb-3">

            {{ $t('send') }}
          
          </button>
        </div>

      </form>
    </div>
  </div>
</template>