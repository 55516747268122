<script setup>

  import {ref, reactive} from 'vue';
  import { useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';

  import { getUserEmail, storeUserEmail, logoutUser } from '@/lib/project.js';

  import NoConnection from "./views/NoConnectionView.vue";
  import NotLoggedIn from "./views/NotLoggedInView.vue";

  const router = useRouter();
  const { t } = useI18n({ legacy: false });

  const logoutModalVisible = ref(false), 
    loggedOut = ref(false);

  let email = getUserEmail();

  if( email != '' ){
    storeUserEmail( email );
  }

  const state = reactive({
    connectionActive: true,
    ieseData: email
  });

  window.addEventListener('offline', () => {
    state.connectionActive = false;
    console.log('navigator offline');
  });
  
  window.addEventListener('online', () => {
     state.connectionActive = true;
  });

  window.setInterval(() => {
      state.connectionActive = navigator.onLine;
   }, 10000);

  if( email.indexOf('@') > -1 && window.location.href.indexOf('ticket') == -1 ){
    router.push('/');
  }

  function logout(){

    logoutUser(email);
    
    state.ieseData = '';
    logoutModalVisible.value = false;
    
  }

</script>

<template>
  
  <div v-if="logoutModalVisible" class="app_modal d-flex align-items-center">

    <div class="app_modal_content p-4">

      <h1 class="mb-3">{{ t('app.logoutQuestion') }}</h1>
      
      <div class="row" v-if="!loggedOut">
        <div class="col-12">
          <p>
            <a class="btn btn-primary text-white"
              @click.prevent="logout()" >
              <i class="bi bi-check"></i> 
              {{ t('app.yesLogout') }}
            </a>
            &nbsp;
            <a class="btn btn-info text-white"
              @click.prevent="logoutModalVisible = false" >
              <i class="bi bi-x"></i> 
              {{ t('app.noLogout') }}
            </a>
          </p>
        </div>
      </div>

      <div class="row text-center" v-if="loggedOut">
        <div class="alert alert-success w-50 m-auto">
          <p>
            <br>
            {{ t('app.sessionClosed') }}
          </p>

          <p>
            
            <a class="btn btn-info text-white"
              @click.prevent="logoutModalVisible = false" >
              <i class="bi bi-house"></i> 
              {{ t('app.home') }}
            </a>

          </p>
        </div>

      </div>
    </div>

  </div>

  <div v-if="!logoutModalVisible">

    <div v-if="!state.connectionActive" >
      <NoConnection />  
    </div>

    <div v-if="state.connectionActive">

      <div v-if="state.ieseData == ''">
        <NotLoggedIn />
      </div>

      <div v-if="state.ieseData != ''">

        <nav class="topbar navbar navbar-light bg-iese fixed-top">
          <img height="30" class="logo" alt="IESE Oncampus" src="./assets/logo_iese_negativo.png">

          <div class="justify-end">
            <a 
              @click.prevent="logoutModalVisible = true"
              class="logout" 
              :title="$t('app.logout')">
              <i class="bi bi-power"></i>
            </a>

            <router-link 
              class="logout"
              to="/config" tagName="a" >
                <i class="bi bi-gear"></i>
            </router-link>
          </div>
        </nav>

        <nav class="nav-iese navbar navbar-light bg-iese fixed-bottom">

          <router-link to="/">
            <i class="bi bi-plus-circle"></i>
            {{ $t('app.createTicket') }}
          </router-link>

           <router-link to="/tickets">
              <i class="bi bi-ticket-perforated"></i>
              {{ $t('app.tickets') }}
            </router-link>

          <router-link to="/etools">
            <i class="bi bi-list" style="padding-top: 10px; padding-bottom: 5px;font-size:32px"></i>
            <!-- {{ $t('etools.etools') }} -->
            
          </router-link>

        </nav>

        <div class="main-container">    
          <router-view/>
        </div>
      </div>

    </div>
  </div>

</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.topbar{
  padding: 10px;
}

.topbar .logo{
  max-width: 200px;
  margin: 10px;
  padding-left: 15px;
}

.topbar a{
  color: #fff;
  font-size: 24px;
  margin-right: 20px;
  float: right;
  display:block;
}

.main-container{
  padding: 100px 20px 20px 20px;
  margin-bottom: 120px;
  min-height: 600px;
}


.bg-iese{
  background: #FF0000 !important;
  color: #fff !important;
}

.color-iese{
  color: #FF0000 !important;
}

nav.nav-iese {
  background: #FF0000;
  padding: 10px;
  z-index: 100000;

}

nav.nav-iese a {
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  padding: 5px 0;
  border-radius: 5px;
  width: 31%;
  margin: 1%;
}

nav.nav-iese a i{
  display: block;
  color: #fff;
  font-size: 24px;
}

nav.nav-iese a.router-link-exact-active,
nav.nav-iese a:hover {
  background: #fff;
  color: red;
}

nav.nav-iese a.router-link-exact-active,
nav.nav-iese a:hover{
  background: #fff;
  color: red;
  min-height: 70px;
}

nav.nav-iese a.router-link-exact-active i,
nav.nav-iese a:hover i{
  color: red;
}

.btn-iese{
  border-color: #FF0000 !important;
  background: #FF0000 !important;
  color: #fff !important;
}

.btn-iese:hover, .btn-iese:active, .btn-iese:focus, .btn-iese.active{
  background: orange !important;
  border-color: orange;
}

form input{
  padding: 2px 10px !important;
}

form input:required {
  border-color: #800000;
}

form input[type="text"]:invalid{
  background-color: #ffdddd;
}

form input[type="text"]:valid {
  border-color: green;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='512' height='512' viewBox='0 0 512 512' %3e%3cpath fill='%2328a745' d='M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z'/%3e%3c/svg%3e") !important;

    background-repeat: no-repeat;
    background-position: right calc(0.175em + 0.0875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);

  }

  .dataTables_filter{
    margin-bottom: 10px;
  }

  .sectionTitle{
    color: #222;
    background: #eee;
    border-left: 7px solid red;
    border-right: 7px solid red;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    font-size: 18px;
    text-align: left;
    padding: 10px 20px 7px 10px;
    margin-bottom: 15px;
    font-weight: bolder;
  }

  span.rounded-pill{
    padding: 5px;
    font-size: 12px;
    min-width: 60px;
  }

  .sectionTitle span.rounded-pill{
    margin: -2px -10px 0 0;
  }

  .form_title{
    color: #222;
    background: #eee;
    border-bottom: 3px solid red;
    font-size: 14px;
    text-align: left;
    padding: 10px 20px 7px 10px;
    margin-bottom: 30px;
    font-weight: bolder;
  }


  .app_modal{
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height: 100vh;
    background: red;
    z-index: 300000;
  }

  .app_modal_content h1{
    font-size: 16px
  }

  .app_modal_content{
    width: 100%;
    z-index: 3000;
    background: #FF0000;
    color: #fff;
  }

</style>