<script setup>
  
  import Settings from '../../lib/settings.js';
  
  import { reactive, computed, defineEmits } from 'vue';
  import { useI18n } from 'vue-i18n';

  import Select2 from 'vue3-select2-component';

  import { setSelect2Options, reservationDateValid } from '@/lib/project.js';

  const emit = defineEmits(['classReservationFormUpdated']);

  const { t } = useI18n({ legacy: false });

  let model = reactive({ 
      subject: t('ticketForm.classReservation'),
      type: 'classReservation',
      // onBehalf: '',
      campus: '',
      bcnCampusZone: '',
      madCampusZone: '',
      spaceType: '',
      reservationDate: '',
      reservationTimeStart: '',
      reservationTimeEnd: '',
      description: ''
  });

  // let spaceTypeOptions = Settings.spaceTypeOptions.map( item => item.text = t( item.text ) );
  // let bcnCampusZonesOptions = Settings.bcnCampusZonesOptions.map( item => item.text = t( item.text  ) );
  // let madCampusZonesOptions = Settings.madCampusZonesOptions.map( item => item.text = t( item.text  ) );

  let spaceTypeOptions = Settings.spaceTypeOptions.map( setSelect2Options(t) );
  let bcnCampusZonesOptions = Settings.bcnCampusZonesOptions.map( setSelect2Options(t) );
  let madCampusZonesOptions = Settings.madCampusZonesOptions.map( setSelect2Options(t) );

  // Select2
  const updateSelected = (selectObj, value) => {
    model[ value ] = selectObj.id;
  };

  // form validation
  const dateValid = computed( () => {
    return reservationDateValid( model.reservationDate, model.reservationTimeStart, model.reservationTimeEnd );
  });

  const formValid = computed(() => {
    
    return model.subject.length > 3 && 
      model.campus.length > 1 &&
      (
        model.bcnCampusZone != '' || 
        model.madCampusZone != ''
      ) && 
       model.spaceType.length > 1 &&
       model.reservationDate.length > 1 &&
       model.reservationTimeStart.length > 1 &&
       model.reservationTimeEnd.length > 1 && 
       reservationDateValid( model.reservationDate, model.reservationTimeStart, model.reservationTimeEnd );

  });

</script>

<template>
  <div class="classReservation">
    <h6 class="mt-3 text-left form_title">
      <i class="bi bi-caret-right"></i>
      {{ $t('ticketForm.classReservation') }}
    </h6>

    <div class="row">
      <div class="col-12">
        <div class="alert alert-info">
          <i class="bi bi-info-circle"></i> 
          {{ $t('ticketForm.classReservationForm.alert') }}: <a href="https://intranet.iese.edu/wp-content/uploads/2019/11/Meeting-Room-Booking-Outlook-Windows-ES-1.pdf" target="_blank">Windows</a> - <a href="https://intranet.iese.edu/wp-content/uploads/2019/11/Meeting-Room-Booking-Outlook-Mac-ES-1.pdf" target="_blank">Mac</a>
        </div>
      </div>
    </div>

    <div class="form row">
      <form :action="Settings.BASE_API + 'tickets/index.php'" method="post">
        
<!--         <div class="mb-3">
          <label for="onBehalf" class="form-label mb-0">{{ $t('ticketForm.classReservationForm.onBehalf') }}:</label>
          <input type="text" v-model="model.onBehalf" class="form-control" id="onBehalf" placeholder="" />
        </div> -->

        <div class="mb-3">
          <label for="subject" class="form-label mb-0">{{ $t('ticketForm.subject') }}:</label>
          <input type="text" v-model="model.subject" class="form-control" id="subject" placeholder="" />
        </div>

        <div class="mb-3">
          <label for="campus" class="form-label mb-0">{{ $t('ticketForm.classReservationForm.campus') }}</label>
          <Select2 
            :placeholder="$t('selector.placeholder')"
            :selectLabel="$t('selector.selectLabel')"
            :options="Settings.campusOptions"
            :settings="Settings.select2Settings"
            v-model="model.campus"
            @select="updateSelected($event, 'campus')"
          />

          <div 
            class="mb-3 mt-3" 
            v-show="model.campus == 'campus_bcn'" >

            <label for="bcnCampusZone" class="form-label mb-0">{{ $t('ticketForm.classReservationForm.bcnCampusZone') }}</label>
            <Select2 
              :placeholder="$t('selector.placeholder')"
              :selectLabel="$t('selector.selectLabel')"
              :options="bcnCampusZonesOptions"
              :settings="Settings.select2Settings"
              v-model="model.bcnCampusZone"
              @select="updateSelected($event, 'bcnCampusZone')"
            />
          </div>

          <div 
            class="mb-3"
            v-show="model.campus == 'campus_mad'">
            <label for="madCampusZone" class="form-label mb-0">{{ $t('ticketForm.classReservationForm.madCampusZone') }}</label>
            <Select2 
              :placeholder="$t('selector.placeholder')"
              :selectLabel="$t('selector.selectLabel')"
              :options="madCampusZonesOptions"
              :settings="Settings.select2Settings"
              v-model="model.madCampusZone"
              @select="updateSelected($event, 'madCampusZone')"
            />
          </div>

        </div>

        <div class="mb-3">
          <label for="spaceType" class="form-label mb-0">{{ $t('ticketForm.classReservationForm.spaceType') }}</label>
          <Select2 
            :placeholder="$t('selector.placeholder')"
            :selectLabel="$t('selector.selectLabel')"
            :options="spaceTypeOptions"
            :settings="Settings.select2Settings"
            v-model="model.spaceType"
            @select="updateSelected($event, 'spaceType')"
          />
        </div>

        <div class="mb-3">
          <label for="reservationDate" class="form-label mb-0">{{ $t('ticketForm.classReservationForm.reservationDate') }}:</label>
          <input type="date" v-model="model.reservationDate" class="form-control" id="reservationDate" placeholder="" />
        </div>

        <div class="mb-3">
          <label for="reservationTimeStart" class="form-label mb-0">{{ $t('ticketForm.classReservationForm.reservationTimeStart') }}:</label>
          <input type="time" v-model="model.reservationTimeStart" class="form-control" id="reservationTimeStart" placeholder="" />
        </div>

        <div class="mb-3">
          <label for="reservationTimeEnd" class="form-label mb-0">{{ $t('ticketForm.classReservationForm.reservationTimeEnd') }}:</label>
          <input type="time" v-model="model.reservationTimeEnd" class="form-control" id="reservationTimeEnd" placeholder="" />
        </div>

        <div 
          v-if="!dateValid"
          class="mb-3 alert alert-warning">
          <i class="bi bi-info-circle"></i> &nbsp;
          {{ $t('errors.datesNotValid') }}
        </div>

        <div class="mb-3">
          <label for="description" class="form-label mb-0">{{ $t('ticketForm.classReservationForm.description') }}:</label>
          <textarea v-model="model.description" class="form-control" id="description" placeholder="" required ></textarea>
        </div>

        <div class="col-auto mb-3">
        
          <button 
            @click.prevent="emit('classReservationFormUpdated', model);"
            :disabled="!formValid"
            type="submit" 
            class="btn btn-iese mb-3">

            {{ $t('send') }}
          
          </button>
        </div>

      </form>
    </div>
  </div>
</template>

<style>
  .select2.select2-container.select2-container--default{
    width: 100% !important;
  }
</style>