<script setup>
  import { useI18n } from 'vue-i18n';

  import { checkAppInstalled, isIOs, isInStandaloneMode } from '@/lib/project';

  import { ref, defineProps } from 'vue';

  const props = defineProps({
    modal: Boolean,
    hidden: Boolean
  });

  let appInstalled = ref( false );
  let hideModal = ref( props.hidden );

  let dontAskAgain = window.localStorage.getItem('dontAskAgain');

  if( dontAskAgain == 'true' ){
    hideModal.value = false;
    appInstalled.value = true;
  }

  const { t } = useI18n({ legacy: false });

  let deferredPrompt = {};

  window.addEventListener("beforeinstallprompt", e => {
    e.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPrompt = e;
  });

  window.addEventListener("appinstalled", () => {
    deferredPrompt = null;
  });
  
  const install = async () => {
    
    if( deferredPrompt && deferredPrompt.prompt ){
      deferredPrompt.prompt();
    }else{
      alert( t('pwa.appIsInstalled') );
    }
  };

  const setDontAskAgainTrue = () => {
    window.localStorage.setItem('dontAskAgain', true);
    hideModal.value = true;
  }

  if( !isInStandaloneMode() ){
    checkAppInstalled().then( ( isInstalled ) =>{
      appInstalled.value = isInstalled;
    });
  }

  

  // console.log(dontAskAgain, 'dontAskAgain');
  // console.log(hideModal.value, 'hideModal');
  // console.log(appInstalled, 'appinstalled');

</script>

<template>

  <div 
    v-if="!hideModal && !appInstalled && !isInStandaloneMode() && !dontAskAgain"
    :class="props.modal ? 'pwa_modal' : ''"
    class="d-flex align-items-center">
        
    <div class="pwa_reload p-4" >

      <img height="30" class="logo mt-4" alt="IESE logo" src="@/assets/logo_iese_negativo.png">

      <h1 class="m-4">{{ t('pwa.addToHomePrompt') }} </h1>
      <p>
        <a 
          v-if="!isIOs()"
          class="btn btn-success" 
          href="#" 
          target="_blank" 
          rel="noopener" 
          @click.prevent="install">{{ t('pwa.addButton') }}</a> &nbsp;

        <a 
          v-if="props.modal == true"
          class="btn btn-warning" 
          href="#" 
          target="_blank" 
          rel="noopener" 
          @click.prevent="hideModal=true">{{ t('pwa.cancelButton') }}</a> &nbsp;
      </p>

      <p v-if="props.modal == true">
        <a class="dontAskAgain" href="#" id="" @click.prevent="setDontAskAgainTrue" > {{ t('pwa.dontAskAgain') }}</a>
      </p>

      <div 
          v-if="isIOs()"
          class="alert alert-info mt-4">
          {{ $t('pwa.iOSAlert') }}
        </div>  

        <div 
          v-if="appInstalled"
          class="alert alert-info mt-4">
          {{ $t('pwa.appIsInstalled') }}
        </div>
      
    </div>
  </div>

</template>

<style scoped>

  .pwa_modal{
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height: 100vh;
    background: red;
    z-index: 300000;
  }

  .pwa_reload h1{
    font-size: 16px
  }

  .pwa_reload{
    width: 100%;
    z-index: 3000;
    background: #FF0000;
    color: #fff;
  }

  a.dontAskAgain{
    color: #fff;
    font-weight: bold;
  }

</style>