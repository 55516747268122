<template>
  <div style="height: 100vh">
    <div class=" d-flex align-items-center  h-screen justify-center" style="height: 100%;">
      <div class="noConnection m-auto">
        <p class="mb-0 p-0">
          <i class="bi bi-wifi-off" style="font-size: 72px;"></i>
        </p>
        <h1 class="m-0">{{ $t('noConnection.noConnection')}}</h1>
        <p>{{ $t('noConnection.noConnectionText')}}</p>
        
      </div>
    </div>
  </div>
</template>
