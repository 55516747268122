<script setup>
  
  import Settings from '../../lib/settings.js';
  
  import { changeMedia, handleUploadError, handleImageRemoved } from '@/lib/project.js';

  import { reactive, computed, defineEmits } from 'vue';

  import Uploader from 'vue-media-upload';
  import Select2 from 'vue3-select2-component';

  import { useI18n } from 'vue-i18n';

  const { t } = useI18n({ legacy: false });

  const emit = defineEmits(['suggestionFormUpdated']);

  let model = reactive({ 
      subject: t('ticketForm.suggestion'),
      type: 'suggestion',
      campus: '',
      description: '',
      files: []
  });

  // File uploads
  const fileAdded = ( mediaObj ) => changeMedia( mediaObj, model );
  const fileRemoved = ( requestObj) => handleImageRemoved( requestObj, model );

  // Select2
  const updateSelected = (selectObj, value) => {
    model[ value ] = selectObj.id;
  };
  
  // form submit
  const formValid = computed( () => {
    
    return model.subject.length > 3 && 
      model.campus.length > 1 &&
      model.description.length > 1;
  
  });

</script>

<template>
  <div class="suggestion">
    <h6 class="mt-3 text-left form_title">
      <i class="bi bi-caret-right"></i>
      {{ $t('ticketForm.suggestion') }}
    </h6>

    <div class="form row">
      <form :action="Settings.BASE_API + 'tickets/index.php'" method="post">
        
        <div class="mb-3">
          <label for="subject" class="form-label mb-0">{{ $t('ticketForm.subject') }}:</label>
          <input type="text" v-model="model.subject" class="form-control" id="subject" placeholder="" />
        </div>

        <div class="mb-3">
          <label for="campus" class="form-label mb-0">{{ $t('ticketForm.suggestionForm.campus') }}</label>
          <Select2 
            :placeholder="$t('selector.placeholder')"
            :selectLabel="$t('selector.selectLabel')"
            :options="Settings.campusOptions"
            :settings="Settings.select2Settings"
            v-model="model.campus"
            @select="updateSelected($event, 'campus')"
          />
        </div>

        <div class="mb-3">
          <label for="description" class="form-label mb-0">{{ $t('ticketForm.suggestionForm.description') }}:</label>
          <textarea v-model="model.description" class="form-control" id="description" placeholder="" required ></textarea>
        </div>

        <div class="mb-3">
          <label for="files" class="form-label mb-0">{{ $t('ticketForm.suggestionForm.files') }}:</label>
          <Uploader
            :server="Settings.BASE_API + 'files/index.php'"
            @add="fileAdded"
            @serverError="handleUploadError"
            @remove="fileRemoved"
            :max="5" 
            :maxFilesize="20"
          />
        </div>

        <div class="col-auto mb-3">
          <button 
            @click.prevent="emit('suggestionFormUpdated', model);"
            :disabled="!formValid"
            type="submit" 
            class="btn btn-iese mb-3">

            {{ $t('send') }}
          
          </button>
        </div>

      </form>
    </div>
  </div>
</template>