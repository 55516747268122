import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import 'bootstrap-icons/font/bootstrap-icons.css'

// Internationalization
import { createI18n } from 'vue-i18n'
import en from "./i18n/en.js";
import es from "./i18n/es.js";

import Settings from "./lib/settings.js"
import Fx from "./lib/functions.js"
// import './sw'

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register( process.env.VUE_APP_URL +  "sw.js")
    .then(serviceWorker => {
      console.log("Service Worker registered: ", serviceWorker);
    })
    .catch(error => {
      console.error("Error registering the Service Worker: ", error);
    });
}

// 1. Ready translated locale messages
// The structure of the locale message is the hierarchical object structure with each locale as the top property
const messages = {
  en,
  es
}

// 2. Create i18n instance with options
const i18n = createI18n({
  legacy: false, // you must set `false`, to use Composition API
  locale: Settings.DEFAULT_LANG, // set locale
  fallbackLocale: Fx.browserLocale(), // set fallback locale
  messages, // set locale messages
  // If you need to specify other options, you can set other options
  // ...
  // 
})

createApp(App)
	.use(store)
	.use(router)
	.use(i18n)
	.mount('#app')

