<script setup>
  
  import Settings from '../../lib/settings.js';

  import { reactive, computed, defineEmits } from 'vue';

  import Select2 from 'vue3-select2-component';

  import { useI18n } from 'vue-i18n';

  const { t } = useI18n({ legacy: false });

  const emit = defineEmits(['parkingFormUpdated']);

  let model = reactive({ 
      subject: t('ticketForm.parking'),
      type: 'parking',
      campus: '',
      parkingDate: '',
      costCentre: '',
      description: ''
  });

  // Select2
  const updateSelected = (selectObj, value) => {
    model[ value ] = selectObj.id;
  };

  // form submit
  const formValid = computed(() => {

    return model.subject.length > 3 && 
      model.campus.length > 1 &&
      model.parkingDate.length > 1 &&
      model.costCentre.length > 0 && 
      Settings.validationPatterns.costCentrePattern.test( model.costCentre );

  });

</script>

<template>
  <div class="parking">
    <h6 class="mt-3 text-left form_title">
      <i class="bi bi-caret-right"></i>
      {{ $t('ticketForm.parking') }}
    </h6>

    <div class="form row">
      <form :action="Settings.BASE_API + 'tickets/index.php'" method="post">
        
        <div class="row">
          <div class="col-12">
            <div class="alert alert-info">
              <i class="bi bi-info-circle"></i> 
              {{ $t('ticketForm.parkingForm.alert') }}: <br><a href="https://intranet.iese.edu/services-for-you/parking/" target="_blank">BCN</a> - <a href="https://intranet.iese.edu/es/servicios-para-ti/parking-madrid/" target="_blank">MAD</a>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <label for="subject" class="form-label mb-0">{{ $t('ticketForm.subject') }}:</label>
          <input type="text" v-model="model.subject" class="form-control" id="subject" placeholder="" />
        </div>

        <div class="mb-3">
          <label for="campus" class="form-label mb-0">{{ $t('ticketForm.parkingForm.campus') }}</label>
          <Select2 
            :placeholder="$t('selector.placeholder')"
            :selectLabel="$t('selector.selectLabel')"
            :options="Settings.campusOptions"
            :settings="Settings.select2Settings"
            v-model="model.campus"
            @select="updateSelected($event, 'campus')"
          />
        </div>

        <div class="mb-3">
          <label for="parkingDate" class="form-label mb-0">{{ $t('ticketForm.parkingForm.parkingDate') }}:</label>
          <input type="date" v-model="model.parkingDate" class="form-control" id="parkingDate" placeholder="" />
        </div>

        <div class="mb-3">
          <label for="costCentre" class="form-label mb-0">{{ $t('ticketForm.parkingForm.costCentre') }}:</label>
          <input type="text" v-model="model.costCentre" class="form-control" id="costCentre" placeholder="" pattern="(^[a-zA-Z][0-9][0-9][0-9]|Particular)$" required />
        </div>

        <div class="mb-3">
          <label for="description" class="form-label mb-0">{{ $t('ticketForm.parkingForm.description') }}:</label>
          <textarea v-model="model.description" class="form-control" id="description" placeholder="" required ></textarea>
        </div>

        <div class="col-auto mb-3">
          <button 
            @click.prevent="emit('parkingFormUpdated', model);"
            :disabled="!formValid"
            type="submit" 
            class="btn btn-iese mb-3">

            {{ $t('send') }}
          
          </button>
        </div>

      </form>
    </div>
  </div>
</template>